<template>
  <div class="app-container">
    <nav>
      <div class="tips">
         <el-form :inline="true" :model="queryForm" ref="queryForm" style="width: 100%">
            <el-form-item label="开票日期" label-width="15%"  style="width: 100%;padding-top:20px" >
              <el-date-picker
                  v-model="startDate"
                  type="date"
                  placeholder="开始日期"
                  :picker-options="startDatePicker"
                  style="width: 45%"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd"
              />
                至
              <el-date-picker
                  v-model="endDate"
                  type="date"
                  placeholder="结束日期"
                  :picker-options="endDatePicker"
                  style="width: 45%"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd"
              />
            </el-form-item>
         </el-form>
          
          <el-button @click="initTable" type="primary" class="btn" v-auth='"invoicedStatistics:lookup"'>查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="handleExport" type="primary" class="btn" v-auth='"invoicedStatistics:import"'><i class="iconfont iconicon-04"></i> 导出</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
       <div class="showAll" v-if="tableData.length">
          <el-checkbox v-model="queryForm.showAll" @keyup.enter.native="handleQuery" @change="handleQuery">
            显示所有公司发票
          </el-checkbox>
        </div>
      
      <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

   
   <export-dialog :visible='exportVis' :cancelDialog='exportCancel'  :confirmDialog='confirmExport'/>


  </div>
</template>

<script>
  // import delDialog from '@/components/Dialog'
  import moment from 'moment'
  import axios from 'axios'
  import compsTable from "@/components/table/table";
  import exportDialog from './dialog/export'
 
  export default {
    components: {  compsTable ,exportDialog},
    data() {
      return {
        queryForm: {
          invoiceTimeStart: '',
          invoiceTimeEnd: '',
          showAll:false,
         },
        isLoading: false,
        hasData: false,
        tableHeader: [
          // 开票公司 税号 正数票总份数(份) 负数票总份数(份) 正数票总金额(元) 负数票总金额(元)
          { prop: "companyName", label: "开票公司", center: 'left' ,},
          {prop:'taxId',label:'税号',center: 'left',},
          {prop:'zpCount',label:'正数票总份数(份)',center: 'left',},
          {prop:'fpCount',label:'负数票总份数(份)',center: 'left',},
          {prop:'zpAmount',label:'正数票总金额(元)',center: 'left',},
          {prop:'fpAmount',label:'负数票总金额(元)',center: 'left',},
         
        ],
       
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 20,
        isdeling: false,
        multipleSelection:[],
        startDate:moment().month(moment().month()).startOf('month').format('YYYY-MM-DD'),//当前月开始时间,
        endDate:moment().month(moment().month()).endOf('month').format('YYYY-MM-DD'),//当前月结束时间,
        startDatePicker: this.beginDate(),
        endDatePicker: this.processDate(),
        
        id:'',
        exportVis:false,
      }
    },
    methods: {
      beginDate(){
        const self = this
        return {
          disabledDate(time){
            if (self.endDate) {
              //如果结束时间不为空，则小于结束时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.endDate).getTime() < ntime.getTime()
            }
          }
        }
      },
      processDate() {
        const  self = this
        return {
          disabledDate(time) {
            if (self.startDate) {  //如果开始时间不为空，则结束时间大于开始时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.startDate).getTime() > ntime.getTime()
            }
          }
        }
      },
      beforeQuery(){
        console.log(this.startDate,'this.startDate')
        if (this.startDate !== undefined && this.startDate !== null) {
          this.queryForm.invoiceTimeStart = this.startDate + ' 00:00:00'
        }else{
          this.queryForm.invoiceTimeStart = ''
        }
        if (this.endDate !== undefined && this.endDate !== null) {
          this.queryForm.invoiceTimeEnd =this.endDate + ' 23:59:59'
        }else{
          this.queryForm.invoiceTimeEnd = ''
        }
        return true;
      },

      handleQuery(){
        this.queryForm.page = 1
        this.initTable()
      },

      handleSelectionChange(val) {
        
        this.multipleSelection = val;
        console.log(this.multipleSelection,'this.multipleSelection')
        this.id = this.multipleSelection.map(item => {
                  return item.companyId
                }).join(',')
      },

      

      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      // 开票统计列表
      initTable(val) {
        if(!this.beforeQuery()){
          return;
        }
        this.isLoading = true;
        this.$store.dispatch("findStatistics", {
          page: this.page,
          rows: this.pageSize,
          showAll: this.queryForm.showAll,
          companyId : this.getToken('companyId'),
          agencyId : this.getToken('agencyCompanyId'),
          userId: this.getToken('userId'),
          beginDate:this.queryForm.invoiceTimeStart,
          endDate:this.queryForm.invoiceTimeEnd
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      confirmExport(type){
        if(type === '1' && this.multipleSelection.length === 0){
          einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        axios({
          method: 'get',
          url: '/api/einvoice/statistics/export',
          responseType: 'blob',
          params: {
            page: this.page,
            rows: this.pageSize,
            companyId : this.getToken('companyId'),
            agencyId : this.getToken('agencyCompanyId'),
            beginDate:this.queryForm.invoiceTimeStart,
            userId: this.getToken('userId'),
            endDate:this.queryForm.invoiceTimeEnd,
            showAll: this.queryForm.showAll,
            ids: type === '1'?this.id:'',
            exportType: type,
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '已开票统计' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
          this.exportVis = false
        }).catch(err=>{
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
        this.exportVis=false;
      },

      
     handleExport(){
        // if (this.multipleSelection.length == 0) {
        //   einvAlert.warning('提示','请先勾选',true,'确定')
        //   return;
        // } 
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
      
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
 .showAll{
  float: right;
  padding-bottom: 10px;
}

</style>
